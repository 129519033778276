import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

declare global {
	interface Window {
		dataLayer: any;
	}
}

@Injectable({ providedIn: 'root' })
export class DataLayerService {
	private _document = inject(DOCUMENT);
	private _platformId = inject(PLATFORM_ID);
	private _window!: Window & typeof globalThis;

	constructor() {
		this._window = this._document.defaultView as Window & typeof globalThis;
	}

	push(object: Record<string, unknown>) {
		if (isPlatformBrowser(this._platformId)) {
			this._window.dataLayer.push(object);
		}
	}
}
