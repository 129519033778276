import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { Observable } from 'rxjs';

export interface GeneralNumbers {
	courses: number;
	open_days: number;
	universities: number;
	users: number;
}

@Injectable({
	providedIn: 'root',
})
// TODO: Refactor
export class GeneralService {
	constructor(
		private http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getGeneralNumbers(): Observable<GeneralNumbers> {
		return this.http.get<GeneralNumbers>(
			this._apiSrv.PHP_URL + 'UniCompare/main_data.php',
		);
	}
}
