import { inject, Injectable } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { BookEvent } from '../models/book-event.models';
import {
	determineTier,
	determineValue,
	isUserToNumber,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class BookEventGTMService {
	private dataLayerSrv = inject(DataLayerService);

	bookEventClick(args: BookEvent) {
		const trackingData = Object.assign(
			{
				eventName: 'book_event_click',
				componentName: args.componentName,
				university: args.uniId,
				universityName: args.uniName,
				eventType: args.eventType,
				date: args.date,
				tier: determineTier(args.uniId),
				value: determineValue(args.uniId),
				user: isUserToNumber(args.isUser),
				event: 'bookEvent',
			},
			args.ugSearchOrderPosition ? { ugso: args.ugSearchOrderPosition } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}
}
