import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class PrimePopupGTMService {
	private dataLayerSrv = inject(DataLayerService);

	impression(uniId: number) {
		this.dataLayerSrv.push({
			eventName: 'prime_popup',
			university: uniId,
			event: 'primePopup',
		});
	}
}
