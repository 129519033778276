import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
	Product,
	ProductEnum,
	ProductId,
	SourceTrackingTypes,
} from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class ProductHandlerService {
	private _product = new BehaviorSubject<Product | null>(null);
	product$ = this._product.asObservable();

	setProduct(product: Product | null): void {
		this._product.next(product);
	}

	getIdByProduct(product: Product): ProductId {
		const products: Product[] = [ProductEnum.Undergraduate, ProductEnum.Postgraduate];

		const productId = products.indexOf(product) + 1;

		return productId as ProductId;
	}

	/**
	 * @param source e.g. "Coventry University"
	 * @param sourceType either "uni" or "course" (or empty string)
	 * @returns e.g. "Coventry University Profile" or "Law Course Profile"
	 */
	configureSource(source: string, sourceType: SourceTrackingTypes): string {
		if (!source) return 'University Profile';

		if (sourceType === 'course') {
			return `${source} Course Profile`;
		} else {
			if (source.toLowerCase().includes('university')) {
				return `${source} Profile`;
			} else {
				return `${source} University Profile`;
			}
		}
	}
}
