import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientValidatorService } from './client-validator/client-validator.service';
import { GeneralService } from './services/general.service';
import { SnigelService } from './services/snigel.service';
import { LinkProcessorService } from './services/link-processor.service';
import { ConversionService } from './analytics/conversions/conversion.service';
import { ConversionEventsService } from './analytics/conversions/conversion-events.service';

@NgModule({
	imports: [CommonModule],
	providers: [
		GeneralService,
		SnigelService,
		ClientValidatorService,
		LinkProcessorService,
		ConversionService,
		ConversionEventsService,
	],
})
export class WebCoreModule {}
