import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavLinkClickService {
	private linkClickSubject = new Subject<string>();

	linkClick$ = this.linkClickSubject.asObservable();

	onLinkClick(key: string, value: string): void {
		const link = `${key}=${value}`;
		this.linkClickSubject.next(link);
	}
}
