import { Injectable } from '@angular/core';
import {
	ClientFeatureOptions,
	ClientFeatures,
	Product,
	ProductEnum,
} from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class ClientValidatorService {
	private readonly underFeatures = [
		ClientFeatures.Undergraduate,
		ClientFeatures.UnderPreview,
	];
	private readonly postFeatures = [
		ClientFeatures.Postgraduate,
		ClientFeatures.PostPreview,
	];
	private readonly clearFeatures = [ClientFeatures.Clearing];

	/**
	 * @param paidFeatures values that are sent from the API
	 * and it should be an array of strings
	 * @param options values we want to check against
	 * it accepts an array of strings || string
	 * @returns true || false
	 */
	checkFeature(
		paidFeatures: ClientFeatureOptions[] | null | undefined,
		options: ClientFeatureOptions | ClientFeatureOptions[],
	): boolean {
		if (!paidFeatures || paidFeatures.length === 0 || !options) return false;

		if (typeof options === 'string') {
			return paidFeatures.includes(options);
		} else {
			return options.some((option) => paidFeatures.includes(option));
		}
	}

	/**
	 *
	 * @param paidFeatures values that are sent from the API
	 * and it should be an array of strings
	 * @param product the product we want to check against (eg. undergraduate, postgraduate)
	 * @returns true || false
	 */
	checkFeatureBasedOnProduct(
		paidFeatures: ClientFeatureOptions[] | undefined,
		product: Product,
	): boolean {
		if ((!paidFeatures || paidFeatures.length <= 0) && !product) return false;

		if (product === ProductEnum.Undergraduate) {
			return this.underFeatures.some((feature) => paidFeatures?.includes(feature));
		} else if (product === ProductEnum.Postgraduate) {
			return this.postFeatures.some((feature) => paidFeatures?.includes(feature));
		} else if (product === ProductEnum.Clearing) {
			return this.clearFeatures.some((feature) => paidFeatures?.includes(feature));
		}
		return false;
	}

	/**
	 * determines whether the client is postgraduate only
	 * @param paidFeatures values that are sent from the API
	 */
	isPostgraduateOnly(paidFeatures: ClientFeatureOptions[]) {
		return (
			this.postFeatures.some((feature) => paidFeatures?.includes(feature)) &&
			!this.underFeatures.some((feature) => paidFeatures?.includes(feature))
		);
	}
}
