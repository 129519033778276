import { FAQ } from '@uc/web/shared/data-models';

const courseFAQ: FAQ[] = [
	{
		question: 'How many courses can you take at university?',
		answer: `<p>
            Most universities will allow you to study at least two subjects at a time. Some universities
            have been known to allow students to study three. Studying two courses at once is known as a
            Joint Honours degree, these can be face-to-face courses at university, or they can be online
            university courses.
        </p>
        <p>
            Check out our article on
            <a href="/advice/student/joint-honours-degree"
                class="blue-link-underline only-tab-focus">
                Joint Honours Degrees</a> to learn more.
        </p>`,
	},
	{
		question: 'When do university courses end?',
		answer: `<p>
            Most university degrees take place over a 3 or 4-year period, depending on what course you're
            sitting. An academic year will typically end in June and then restart again in late
            September/early October.
        </p>`,
	},
	{
		question: 'How many university courses can you apply for?',
		answer: `<p>
            You can apply for up to 5 different courses. You can apply for all 5 of these at the same
            university, or you can apply for 1 course at 5 different universities. You can find any of these
            using our university degree finder and the university courses list.
        </p>`,
	},
	{
		question: 'What are foundation courses at university?',
		answer: `<p>
            A foundation course is a preparatory course, which is designed to teach you skills and
            specific knowledge in a subject area before you begin your university degree. Foundation uni
            courses typically take between 1 and 2 years depending on the subject and how frequently you
            study.
        </p>
        <p>
            To learn more, check out our article on
            <a href="/advice/student/foundation-degree"
                class="blue-link-underline only-tab-focus">
                Foundation degrees</a>.
        </p>`,
	},
	{
		question: 'How can I switch courses at university?',
		answer: `<p>
            The process for switching courses is fairly straightforward. First, you will need to find which
            course suits you best, then you will need to speak to your course tutor and begin the process of
            transferring. Then you will need to attend an interview with your new tutor or university and
            await their decision before you move. The process is the same for undergraduate courses and
            postgraduate courses.
        </p>`,
	},
	{
		question: 'What is Clearing?',
		answer: `<p>
            Clearing is a process that universities will go through in order to fill out the remaining
            places on their courses. If you didn’t meet the conditions of any of the offers you received
            from the universities you applied to, you will enter the Clearing process, which allows you to
            apply to another university instead.
        </p>
        <p>
            You will also take part in the Clearing process if you have declined an offer at your firm
            choice, if you didn’t receive any offers or if you paid the multiple-choice application fee to
            UCAS.
        </p>
        <p>
            Searching for courses isn’t a problem because most universities will advertise any courses that
            still have spaces available and most uni course searches will have a Clearing filter and provide
            you with a lost of degree courses for you to choose from.
        </p>
        <p>
            For more information check out our article on
            <a href="advice/ucas/what-is-ucas-clearing"
                class="blue-link-underline only-tab-focus">
                Clearing</a>.
        </p>`,
	},
	{
		question: 'What is Adjustment?',
		answer: `<p>
            Adjustment is for students who have gotten into their desired university but want to apply
            elsewhere. Where Clearing is mainly reserved for those who didn’t achieve the required grades,
            Adjustment is the opposite.
        </p>
        <p>
            Adjustment allows you to reconsider where you want to study, without the worry of losing your
            secured place. If you have exceeded the expectations of your firm offer, then you are
            automatically eligible to apply through Adjustment.
        </p>
        <p>

            As with Clearing, you can simply search for any subject and apply as you see fit. Both Clearing
            and Adjustment processes can be used for undergraduate courses and postgraduate courses.
        </p>
        <p class="pb-6">
            Check out our article on
            <a href="/advice/student/ucas-adjustment"
                class="blue-link-underline only-tab-focus">
                Adjustment</a> to learn more.
        </p>`,
	},
];

const clearingFAQ: FAQ[] = [
	{
		question: 'What is UCAS Clearing?',
		answer: `<p>
            Clearing matches potential students with universities that still have spaces available on some of their courses.
            You can enter Clearing if you haven't been accepted onto a course you applied for,
            missed the original university application deadline,
            or would like to change what you will be studying.
        </p>`,
	},
	{
		question: 'How do I apply to a university through Clearing?',
		answer: `<p>
            First, you will need to receive an informal offer from a university.
            This is done by calling up universities' Clearing hotlines and asking for a spot on a course you're interested in.
            When you have an informal offer, you will need to add the course to your UCAS Track account.
            Once the university has formalised your offer on Track, the space is all yours.
        </p>
        <p>
            If you are still holding an offer from your insurance choice or your firm choice,
            you will not be allowed to enter the Clearing process.
            You will need to reject offers from your insurance and your firm before you can accept another offer.
            It is recommended that you speak to the university of the Clearing course you are interested in before making any decisions.
            Once you have a verbal offer from them, then you can reject your other choices.
        </p>`,
	},
	{
		question: 'How many university Clearing courses can I add?',
		answer: `<p>
            You can only add one Clearing choice at a time.
            If the university or college does not confirm your place,
            then you are able to add another one.
        </p>`,
	},
	{
		question: 'When does UCAS Clearing start and end?',
		answer: `<p>
            Clearing starts on Wednesday, the 5th of July 2023 and ends on Wednesday, the 18th of October 2023.
        </p>`,
	},
	{
		question: 'Do I need a personal statement for Clearing?',
		answer: `<p>
            You do not need a personal statement for Clearing.
            However, you will need one if you are applying for university for the first time through the service.
            If you are going to be speaking to universities on the phone at any point,
            it is recommended that you keep your personal statement close by,
            in case you need to refer to it at any point.
        </p>`,
	},
	{
		question: 'What is Clearing Plus?',
		answer: `<p>
            Clearing Plus is a free service set up by UCAS.
            The service is designed to speed up the Clearing process by finding personalised course matches for students.
            The service is overseen by the UCAS Clearing team,
            who will use what they know about you from your original application
            and what they know about the types of students universities are looking for to pair you with the ideal course.
        </p>`,
	},
	{
		question: 'Can I apply directly to a university for Clearing?',
		answer: `<p>
            Yes, you can. However, any offer made to you regarding Clearing courses will still need to be accepted through UCAS.
        </p>`,
	},
];

const rankingsFAQ: FAQ[] = [
	{
		question: 'Which university in the UK is the hardest to get into?',
		answer: `<p>
            Every university has a competitive entry requirements, while some may be more popular than others so entry processes can be more complex. Typically, the University of Cambridge, the University of Oxford and the London School of Economics and Political Science (LSE) are known for being hard to get into. 
        </p>`,
	},
	{
		question: 'How can I choose a good university in the UK?',
		answer: `<p>
            Decide which element of university is most important to you, such as location close to home, good career prospects or student social life. Use our rankings to narrow down your search, attend open days, seek the opinions of other students. For more guidance, read our advice article about finding the right course for you.
        </p>`,
	},
	{
		question: 'What is the top-ranked university in the UK?',
		answer: `<p>
            For 2025, students have ranked [Top University] as the top university!
        </p>`,
	},
	{
		question: 'Does ranking matter in UK universities?',
		answer: `<p>
            Rankings are a great way for you to understand how good a university is and what they’re known for - whether it’s academic performance, employability skills or student satisfaction. With our rankings, you’ll get a sense of what universities students love and why.
        </p>`,
	},
];

export { courseFAQ, clearingFAQ, rankingsFAQ };
