import { Injectable } from '@angular/core';
interface Window {
	webkit?: any;
}
declare let window: Window;
declare let Android: any;

type MessageHandler = {
	onPrivacyPolicy(link: string): void;
	onWebsite(externalUrl: string): void;
	onRequestInfo(uniId: number): void;
	onProspectusOrderSuccess(success: boolean): void;
	onCourseSearch(searchTerm: string): void;
	onPromoSlot(link: string): void;
	onLogin(token: string): void;
};

@Injectable({ providedIn: 'root' })
export class InAppBrowserMessageHandlerService {
	ios: MessageHandler = {
		onPrivacyPolicy: (link: string) => {
			window?.webkit?.messageHandlers?.onPrivacyPolicy?.postMessage(link);
		},
		onWebsite: (externalUrl: string) => {
			window?.webkit?.messageHandlers?.onWebsite?.postMessage(externalUrl);
		},
		onRequestInfo: (uniId: number) => {
			window?.webkit?.messageHandlers?.onRequestInfo?.postMessage(uniId);
		},
		onProspectusOrderSuccess: (success: boolean) => {
			window?.webkit?.messageHandlers?.onProspectusOrderSuccess?.postMessage(
				success,
			);
		},
		onCourseSearch: (searchTerm: string) => {
			window?.webkit?.messageHandlers?.onCourseSearch?.postMessage(searchTerm);
		},
		onPromoSlot: (link: string) => {
			window?.webkit?.messageHandlers?.onPromoSlot?.postMessage(link);
		},
		onLogin: (token: string) => {
			window?.webkit?.messageHandlers?.onLogin?.postMessage(token);
		},
	};

	// TODO: Remove handlers for Android
	android = {
		onPrivacyPolicy: (link: string) => {
			if (
				typeof Android !== 'undefined' &&
				typeof Android.onPrivacyPolicy === 'function'
			) {
				Android.onPrivacyPolicy(link);
			}
		},
		onWebsite: (externalUrl: string) => {
			if (
				typeof Android !== 'undefined' &&
				typeof Android.onWebsite === 'function'
			) {
				Android.onWebsite(externalUrl);
			}
		},
		onRequestInfo: (uniId: number) => {
			if (
				typeof Android !== 'undefined' &&
				typeof Android.onRequestInfo === 'function'
			) {
				Android.onRequestInfo(uniId);
			}
		},
		onProspectusOrderSuccess: (success: boolean) => {
			if (
				typeof Android !== 'undefined' &&
				typeof Android.onProspectusOrderSuccess === 'function'
			) {
				Android.onProspectusOrderSuccess(success);
			}
		},
	};
}
