import { AbstractControl } from '@angular/forms';
import { ValidatorResult } from './validator.model';

export const dateRegex =
	/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;

export const postcodeValidator = (control: AbstractControl): ValidatorResult => {
	const postcode = control.value;
	const regex =
		/^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

	return regex.test(postcode) ? null : { postcodeInvalid: true };
};

export const emailValidator = (control: AbstractControl): ValidatorResult => {
	const email = control.value;
	const regex = /^[a-z0-9]+[+a-z0-9._-]+@[a-z0-9-]+\.[a-z.]{2,15}$/i;

	return regex.test(email) ? null : { emailInvalid: true };
};

// validates date format is dd/mm/yyyy and user is over 14 years old
export const dateValidator = (control: AbstractControl): ValidatorResult => {
	const date = control.value;
	let overFourteen = true;
	const regex = dateRegex;

	const currentDate = new Date();
	const year = currentDate.getFullYear() - 14;

	let inputYear = year;
	if (date?.length === 10) {
		inputYear = +date.substring(6);
	}

	if (inputYear > year) {
		overFourteen = false;
	}

	return regex.test(date) && overFourteen ? null : { dateInvalid: true };
};

export const phoneValidator = (control: AbstractControl): ValidatorResult => {
	const email = control.value;
	const regex = /^[\d]{4,15}$/;

	return regex.test(email) ? null : { phoneInvalid: true };
};

export const matchingPasswordValidator = (control: AbstractControl): ValidatorResult => {
	const pass1 = control.get('password1')?.value;
	const pass2 = control.get('password2')?.value;

	return pass1 === pass2 ? null : { matchInvalid: true };
};
