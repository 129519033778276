import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class ArticleGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/*
  User Clicks share button on any article
*/
	shareArticle(articleName: string, shareType: string) {
		this.dataLayerSrv.push({
			eventName: 'share_article',
			article: articleName,
			sharedTo: shareType,
			event: 'shareArticle',
		});
	}
}
