import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CounterService {
	private _count = 0;

	get count() {
		return this._count;
	}

	/**
	 * Increments the counter by 1
	 */
	setCount() {
		this._count++;
	}

	reset() {
		this._count = 0;
	}
}
