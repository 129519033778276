import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { InAppBrowserPlatform } from '../models/gtm.models';

@Injectable({ providedIn: 'root' })
export class RegisterGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/**
	 * When user clicks the Register button
	 */
	registerClick(componentName: string) {
		this.dataLayerSrv.push({
			eventName: 'register_button_click',
			componentName: componentName,
			event: 'registerClick',
		});
	}

	/**
	 * When the user starts the registration form,  fires when first field is entered
	 */
	registerStart(inAppBrowser: boolean, inAppBrowserPlatform: InAppBrowserPlatform) {
		this.dataLayerSrv.push({
			eventName: 'registration_start',
			inAppBrowser,
			inAppBrowserPlatform,
			event: 'registerStart',
		});
	}

	/**
	 * When user successfully registers for account . . time to complete the value should be pass in minutes for eg: 30,32 or 37 etc
	 */
	registerSuccess(
		userId: string,
		timeToComplete: number,
		inAppBrowser: boolean,
		inAppBrowserPlatform: InAppBrowserPlatform,
	) {
		this.dataLayerSrv.push({
			eventName: 'registration_success',
			time: timeToComplete, // pass the value in seconds,eg:30 or37 etc.
			userId: userId,
			inAppBrowser,
			inAppBrowserPlatform,
			event: 'registerSuccess',
		});
	}
}
