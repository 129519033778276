import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class LinkProcessorService {
	window: Window & typeof globalThis;
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
	) {
		this.window = this.document.defaultView as Window & typeof globalThis;
	}

	processLinks(e: Event) {
		const element = e.target as HTMLElement;
		const href = element.getAttribute('href');
		const type = element?.nodeName;

		if (type === 'A' && href) {
			e.preventDefault();

			//scroll to reference functionality on advice pages
			if (href === '#ref') {
				const references = this.document.querySelector('.ref');

				references?.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});

				//scroll to other page IDs
			} else if (href.startsWith('#')) {
				const elementWithId = this.document.getElementById(href.substring(1));
				elementWithId?.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
				//internal link
			} else if (
				this.isLocalLink(href) &&
				element.getAttribute('target') !== '_blank'
			) {
				this.router.navigate([this.getPathFromUri(href)], {
					queryParams: this.getParamsFromUri(href),
					queryParamsHandling: 'merge',
				});

				//external link
			} else {
				this.window.open(href);
			}
		}
	}

	/**
	 * @param uri e.g. https://universitycompare.com/advice/student/mitigating-circumstances
	 * @returns /advice/student/mitigating-circumstances
	 */
	private getPathFromUri(uri = '') {
		const ele = this.document.createElement('a');
		ele.href = uri;
		return ele.pathname;
	}

	private getParamsFromUri(uri = '') {
		const parts = uri.split('?')[1]?.split('&');
		const params = parts?.reduce((acc, part) => {
			const [key, value] = part.split('=');
			return { ...acc, [key]: value };
		}, {});

		return params;
	}

	private isLocalLink(uri = '') {
		return uri.startsWith('/') || uri.includes('universitycompare.com');
	}
}
