import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class NullResultsGTMService {
	private dataLayerSrv = inject(DataLayerService);

	nullResults(searchTerm: string) {
		this.dataLayerSrv.push({
			eventName: 'null_results',
			searchQuery: searchTerm,
			event: 'nullResults',
		});
	}
}
