import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

declare let gtag: (type: string, event: string, options: any) => void;
declare let fbq: (type: string, event: string, options: any) => void;

@Injectable({ providedIn: 'root' })
export class ConversionService {
	private _platformId = inject(PLATFORM_ID);

	sendGoogleEvent(options: Record<string, any>) {
		if (isPlatformBrowser(this._platformId)) {
			gtag('event', 'conversion', options);
		}
	}

	sendFacebookEvent(options: Record<string, any>) {
		if (isPlatformBrowser(this._platformId)) {
			fbq('track', 'SubmitApplication', options);
		}
	}
}
