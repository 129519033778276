import { Injectable } from '@angular/core';
import {
	Product,
	ProductEnum,
	UniversityCourseDetails,
	UniversityDetails,
	UniversityProfile,
} from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class UniDetailsByProductService {
	getDetailByProduct(
		uni: Partial<UniversityProfile> | UniversityCourseDetails | UniversityDetails,
		key: 'external_url' | 'cover_image' | 'external_prospectus_url',
		product: Product | null,
	) {
		const { undergraduate, postgraduate } = uni;
		const ugLink = undergraduate ? undergraduate[key] : null;
		const pgLink = postgraduate ? postgraduate[key] : null;

		if (!product) {
			return ugLink || pgLink || '';
		} else if (product === ProductEnum.Postgraduate) {
			return pgLink || '';
		} else {
			return ugLink || '';
		}
	}
}
