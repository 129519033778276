import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { InAppBrowserPlatform } from '../../analytics/models';
import { normalizePlatformName } from '@uc/utilities';
import { AuthenticationService, CustomCookieService } from '@uc/shared/authentication';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class InAppBrowserService {
	private _platform = inject(PLATFORM_ID);
	private _customCookieSrv = inject(CustomCookieService);
	private _authSrv = inject(AuthenticationService);

	private _isInAppBrowser = false;
	private _inAppBrowserPlatform: InAppBrowserPlatform = null;
	private _SSOId: string | null = null;

	getIsInAppBrowser() {
		return this._isInAppBrowser;
	}
	setIsInAppBrowser(isInAppBrowser: boolean) {
		this._isInAppBrowser = isInAppBrowser;
	}

	getPlatform() {
		return this._inAppBrowserPlatform ? this._inAppBrowserPlatform : null;
	}
	setPlatform(platform: string) {
		this._inAppBrowserPlatform = normalizePlatformName(platform);
	}

	getSSOId() {
		return this._SSOId;
	}
	setSSOId(SSOId: string | null) {
		if (typeof SSOId !== 'string') {
			this._SSOId = null;
		}
		this._SSOId = SSOId;
	}

	resetState() {
		this._isInAppBrowser = false;
		this._inAppBrowserPlatform = null;
		this._SSOId = null;

		this._authSrv.deleteUcCookies();
		this._customCookieSrv.reset();

		if (isPlatformBrowser(this._platform)) {
			localStorage.clear();
		}
	}
}
