import { inject, Injectable } from '@angular/core';
import { ConversionService } from './conversion.service';

@Injectable({ providedIn: 'root' })
export class ConversionEventsService {
	private _gtagSrv = inject(ConversionService);

	/**
	 * Start Google Ads Conversions
	 */
	prospectusOrder(numProspectuses: number) {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/0MOHCISXmooDEJKAmeED',
			value: numProspectuses ?? 1,
		});
	}

	prospectusEnquiryTick(numEnquiries: number) {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/WLGKCOqq-6IYEJKAmeED',
			value: numEnquiries ?? 1,
		});
	}

	requestInfo() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/NpWLCOrcmooDEJKAmeED',
			value: 1,
		});
	}

	createAccount() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/dXKUCLDgmYoDEJKAmeED',
			value: 1,
		});
	}

	login() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/gr9jCJj5mIoDEJKAmeED',
			value: 1,
		});
	}

	saveCourse() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/72sECJu_64kDEJKAmeED',
			value: 1,
		});
	}

	saveUni() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/gtl6COOUm4oDEJKAmeED',
			value: 1,
		});
	}

	visitWebsite() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/m6oVCLybzMoDEJKAmeED',
		});
	}

	quizRegistrationSuccess() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/R4rxCNuo_dwDEJKAmeED',
		});
	}

	quickQuizSubmit() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/K-rCCICZyq4ZEJKAmeED',
		});
	}

	longQuizSubmit() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/RXSrCIOZyq4ZEJKAmeED',
		});
	}

	callUni() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/tfs3CK77y8oDEJKAmeED',
		});
	}

	clickRegisterInterest() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/Cy2eCMD_-8oDEJKAmeED',
		});
	}

	submitRegisterInterest() {
		this._gtagSrv.sendGoogleEvent({
			send_to: 'AW-1009139730/qGOGCLmbzMoDEJKAmeED',
		});
	}
	/**
	 * End Google Ads Conversions
	 */

	/**
	 * Start Facebook Conversions
	 */
	prospectusOrderFB(prospectusOrdered: number) {
		this._gtagSrv.sendFacebookEvent({ currency: 'GBP', value: prospectusOrdered });
	}
	/**
	 * End Facebook Conversions
	 */
}
