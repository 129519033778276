import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { PromoSlotComponentNameType } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class PromoSlotGTMService {
	private dataLayerSrv = inject(DataLayerService);

	clickPromoSlot(
		slotNumber: number,
		componentName: PromoSlotComponentNameType,
		uniId: number | undefined,
		uniName: string | undefined,
	) {
		this.dataLayerSrv.push({
			eventName: 'promo_slot_click',
			componentName,
			university: uniId || 'default',
			universityName: uniName,
			slot_number: slotNumber,
			event: 'promoSlotClick',
		});
	}
}
