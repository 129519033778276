import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FAQ, FaqEntity, UniversitySchema } from '@uc/web/shared/data-models';

@Injectable()
export class JsonLdSchemaService {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	addHomePageSchema() {
		let script = this.document.head.querySelector(`script[id='home-page-schema']`);
		if (!script) {
			script = this._createScriptElement('home-page-schema');
		}
		script.textContent = JSON.stringify({
			'@context': 'https://schema.org',
			'@type': 'WebSite',
			name: 'Uni Compare',
			url: 'https://universitycompare.com/',
		});
		this.document.head.appendChild(script);
	}

	addUniversitySchema(data: UniversitySchema) {
		/*
		 * inserts json script into head HTML tag
		 * AggregateRating Schema, it shows up in search results with how many stars the university is, etc.
		 */

		let script = this.document.head.querySelector(`script[id='university-schema']`);
		if (!script) {
			script = this._createScriptElement('university-schema');
		}

		script.textContent = JSON.stringify({
			'@context': 'http://schema.org',
			'@type': 'CollegeOrUniversity',
			name: data.name,
			address: {
				'@type': 'PostalAddress',
				addressLocality: data.region,
				addressRegion: data.region,
				streetAddress: data.address,
				postalCode: data.postcode,
			},
			aggregateRating: {
				'@type': 'AggregateRating',
				ratingValue: data.rating,
				reviewCount: data.reviewsCount,
			},
		});
		this.document.head.appendChild(script);
	}

	addFaqSchema(faqs: FAQ[]): void {
		// when ssr, schema already added
		if (this.document.getElementById('faq-schema')) return;

		const scriptElement = this._createScriptElement('faq-schema');

		const content = {
			'@context': 'https://schema.org',
			'@type': 'FAQPage',
			mainEntity: this._createMainEntity(faqs),
		};

		scriptElement.innerHTML = JSON.stringify(content);

		const headElement = this.document.getElementsByTagName('head')[0];
		headElement.appendChild(scriptElement);
	}

	removeSchema(schemaId: string) {
		const script = this.document.getElementById(schemaId);
		if (script) {
			script.remove();
		}
	}

	private _createScriptElement(id: string) {
		const script = this.document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.setAttribute('id', id);
		return script;
	}

	private _createMainEntity(faqs: FAQ[]) {
		const result: FaqEntity[] = [];

		faqs.forEach((faq) => {
			const entity = {
				'@type': 'Question',
				name: faq.question,
				acceptedAnswer: {
					'@type': 'Answer',
					text: faq.answer,
				},
			};

			result.push(entity);
		});

		return result;
	}
}
