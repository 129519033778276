import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	determineTier,
	determineValue,
	isUserToNumber,
} from '../shared-functions/shared-functions';
import { InAppBrowserPlatform } from '../models/gtm.models';

@Injectable({ providedIn: 'root' })
export class RequestInfoGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/*
	 * When the user starts the form, fires when first field is entered
	 */
	enquiryStart() {
		this.dataLayerSrv.push({
			eventName: 'enquiry_form_start',
			event: 'enquiryStart',
		});
	}

	/*
	 * User removes default text in enquiry input, and writes a custom enquiry
	 */
	customEnquiry() {
		this.dataLayerSrv.push({
			eventName: 'custom_enquiry',
			event: 'customEnquiry',
		});
	}

	/*
	 * When user successfully fills the enquiry form and reaches success page
	 */
	enquirySuccess(
		timeToComplete: number,
		isUser: boolean,
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
	) {
		const tier = determineTier(uniId);
		const value = determineValue(uniId);

		const trackingData = Object.assign(
			{
				eventName: 'enquiry_form_success',
				time: timeToComplete,
				tier: tier,
				value: value,
				user: isUserToNumber(isUser),
				university: uniId,
				universityName: uniName,
				event: 'enquirySuccess',
			},
			ugSearchOrderPosition ? { ugso: ugSearchOrderPosition } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/**
	 * Request Information Button Click
	 */
	requestInfoClick(
		componentName: string,
		ugSearchOrderPosition: number | null,
		inAppBrowserPlatform: InAppBrowserPlatform = null,
	) {
		const trackingData = Object.assign(
			{
				eventName: 'request_information_button_click',
				componentName: componentName,
				event: 'requestInfoClick',
				inAppBrowserPlatform: inAppBrowserPlatform ?? false,
			},
			ugSearchOrderPosition ? { ugso: ugSearchOrderPosition } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}
}
