import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Directive,
	EventEmitter,
	inject,
	Input,
	Output,
	PLATFORM_ID,
	ViewContainerRef,
} from '@angular/core';
import { CounterService } from '../../services/counter.service';

@Directive({
	selector: '[ucImpression]',
	standalone: true,
})
export class ImpressionDirective implements AfterViewInit {
	@Input() count!: boolean;
	@Input() trackLostImpression = false;
	@Output() onImpression = new EventEmitter<boolean>();
	@Output() onLostImpression = new EventEmitter<boolean>();

	private _platformId = inject(PLATFORM_ID);

	constructor(
		private vcRef: ViewContainerRef,
		private counterSrv: CounterService,
	) {}

	ngAfterViewInit() {
		if (isPlatformBrowser(this._platformId)) {
			const observedElement = this.vcRef.element.nativeElement.parentElement;
			const observer = new IntersectionObserver(([entry]) => {
				this.renderContents(entry.isIntersecting);
			});
			observer.observe(observedElement);
		}
	}
	renderContents(isIntersecting: boolean) {
		this.vcRef.clear();
		if (isIntersecting) {
			this.onImpression.emit(true);
			if (this.count) {
				this.counterSrv.setCount();
			}
		} else {
			if (this.trackLostImpression) this.onLostImpression.emit(true);
		}
	}
}
