import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { Product } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class VideoGTMService {
	private dataLayerSrv = inject(DataLayerService);

	start(uniId: number, product: Product) {
		this.dataLayerSrv.push({
			eventName: 'video_start',
			university: uniId,
			product: product,
			event: 'videoStart',
		});
	}

	completed(uniId: number, product: Product) {
		this.dataLayerSrv.push({
			eventName: 'video_complete',
			university: uniId,
			product: product,
			event: 'videoComplete',
		});
	}

	impression(uniId: number, product: Product) {
		this.dataLayerSrv.push({
			eventName: 'video_impression',
			university: uniId,
			product: product,
			event: 'videoImpression',
		});
	}
}
