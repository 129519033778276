import { inject, Injectable } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class LoadMoreGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/**
	 * When user clicks load more button
	 */
	loadResults(searchTerm: string) {
		this.dataLayerSrv.push({
			eventName: 'load_more',
			searchQuery: searchTerm,
			event: 'loadResults',
		});
	}
}
