import {
	ClientFeatureOptions,
	ClientFeatures,
	FeaturedCalls,
	FeaturedClearing,
	FeaturedClearingVisitWebsite,
	FeaturedPostgraduate,
	FeaturedRegisteredInterest,
	FeaturedUndergraduate,
} from '@uc/web/shared/data-models';

export class ClientFeatureHandler {
	isFeatured: FeaturedUndergraduate | FeaturedPostgraduate = false;
	undergraduate: FeaturedUndergraduate = false;
	underPreview: FeaturedUndergraduate = false;
	postgraduate: FeaturedPostgraduate = false;
	postPreview: FeaturedPostgraduate = false;
	isClearingFeatured:
		| FeaturedClearing
		| FeaturedCalls
		| FeaturedRegisteredInterest
		| FeaturedClearingVisitWebsite = false;
	clearing: FeaturedClearing = false;
	clearingPreview: FeaturedClearing = false;
	calls: FeaturedCalls = false;
	registerInterest: FeaturedRegisteredInterest = false;
	clearingVisitWebsite: FeaturedClearingVisitWebsite = false;
	isPostExclusive = false;

	private readonly _features = [
		ClientFeatures.Undergraduate,
		ClientFeatures.Postgraduate,
	];

	private readonly _previewFeatures = [
		ClientFeatures.UnderPreview,
		ClientFeatures.PostPreview,
	];

	private readonly _clearingFeatures = [
		ClientFeatures.Clearing,
		ClientFeatures.Calls,
		ClientFeatures.RegisterInterest,
		ClientFeatures.ClearingVisitWebsite,
	];

	private readonly _clearingPreviewFeature = ClientFeatures.ClearingPreview;

	constructor(
		private _paidFeatures: ClientFeatureOptions[],
		private _previewEnabled: boolean,
	) {
		this._initialiseFeatures();
		this._hasFeature();
		this._hasClearingFeature();
		this._isPostExclusive();
	}

	private _initialiseFeatures(): void {
		if (!this._paidFeatures || this._paidFeatures.length === 0) return;
		this.undergraduate = this._paidFeatures.includes(ClientFeatures.Undergraduate);
		this.underPreview = this._paidFeatures.includes(ClientFeatures.UnderPreview);
		this.postgraduate = this._paidFeatures.includes(ClientFeatures.Postgraduate);
		this.postPreview = this._paidFeatures.includes(ClientFeatures.PostPreview);
		this.clearing = this._paidFeatures.includes(ClientFeatures.Clearing);
		this.clearingPreview = this._paidFeatures.includes(
			ClientFeatures.ClearingPreview,
		);
		this.calls = this._paidFeatures.includes(ClientFeatures.Calls);
		this.registerInterest = this._paidFeatures.includes(
			ClientFeatures.RegisterInterest,
		);
		this.clearingVisitWebsite = this._paidFeatures.includes(
			ClientFeatures.ClearingVisitWebsite,
		);
	}

	private _hasFeature(): void {
		if (!this._paidFeatures || this._paidFeatures.length === 0) return;
		const isPremium = this._features.some((feature) =>
			this._paidFeatures.includes(feature),
		);

		const isPremiumPreview = this._previewFeatures.some((feature) =>
			this._paidFeatures.includes(feature),
		);

		this.isFeatured = isPremium || (isPremiumPreview && this._previewEnabled);
	}

	private _hasClearingFeature(): void {
		if (!this._paidFeatures || this._paidFeatures.length === 0) return;
		const isClearingFeatured = this._clearingFeatures.some((feature) =>
			this._paidFeatures.includes(feature),
		);

		const isClearingPreviewFeatured = this._paidFeatures.includes(
			this._clearingPreviewFeature,
		);

		this.isClearingFeatured =
			isClearingFeatured || (isClearingPreviewFeatured && this._previewEnabled);
	}

	private _isPostExclusive(): void {
		this.isPostExclusive = this._paidFeatures.includes(
			ClientFeatures.PostgraduateLandingPage,
		);
	}
}
