import { Injectable } from '@angular/core';

/**
 * TODO: to be fixed on the events page and send the correct type of object when sending the @filterBy parameter
 * currently the if condition on line 36 will never be true
 * the condition is checking if @key === @value from the @filterBy object
 * e.g filterBy = {
 * key: type,
 * value: 'recommended'
 * }
 * key => 'type'
 * value => 'recommended'
 * object.type === 'recommended => true
 * filter out the object
 */

// warning: this mutates the original array
interface FilterBy {
	key: string;
	value: string | boolean;
}
@Injectable({
	providedIn: 'root',
})
export class InsertService {
	insert(arr: any[], insertFrequency: number, recommended: any[], filterBy: FilterBy) {
		const { key, value } = filterBy;

		if (!arr || arr.length <= 0 || !recommended || recommended.length <= 0)
			return arr;

		// filter out recommended
		// in order to recalculate the position to be inserted
		if (arr.some((elem) => elem[key] === value)) {
			arr = arr.filter((elem) => elem[key] != value);
		}

		let results = arr;
		// index position
		let position = insertFrequency - 1;
		// if recommended.length > results.length
		// add recommended at the end of the array
		let insertAll = false;

		let i = 0;
		let loopTimes = 1;
		let recommendedIndex = 0;
		while (i < loopTimes) {
			//if more than one keyword
			if (recommendedIndex >= recommended?.length) {
				recommendedIndex = 0;
			}

			loopTimes = Math.floor(results.length / insertFrequency);

			// do not insert on the last index
			if (
				results.length > insertFrequency &&
				results.length - (1 % insertFrequency) == 0
			) {
				loopTimes = loopTimes - 1;
			}

			if (insertAll || results.length < insertFrequency) {
				results = [
					...results,
					...recommended.slice(recommendedIndex, recommended.length),
				];
			} else {
				results = [
					...results.slice(0, position),
					recommended[recommendedIndex],
					...results.slice(position),
				];
			}

			if (i + 1 === loopTimes && loopTimes < recommended.length) {
				loopTimes++;
				insertAll = true;
			}

			position += insertFrequency;
			recommendedIndex++;
			i++;
		}

		return results;
	}
}
