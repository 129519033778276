import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class LoginGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/**
	 * When user clicks sign in button
	 */
	loginClick() {
		this.dataLayerSrv.push({
			eventName: 'login_click',
			event: 'loginClick',
		});
	}

	/**
	 * When user successfully logs in
	 */
	loginSuccess(userId: number, timeToComplete: number) {
		this.dataLayerSrv.push({
			eventName: 'login_success',
			time: timeToComplete, //pass the value in seconds ,eg:30 or37 etc.
			userId: userId,
			loginStatus: 'yes',
			event: 'loginSuccess',
		});
	}
}
