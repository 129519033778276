import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { CallUniComponentName } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class CallUniversityGTMService {
	private dataLayerSrv = inject(DataLayerService);

	callUniClick(
		uniId: number,
		uniName: string,
		componentName: CallUniComponentName,
		cleSearchOrderPosition: number,
	) {
		this.dataLayerSrv.push({
			eventName: 'call_university_button_click',
			university: uniId,
			universityName: uniName,
			componentName: componentName,
			event: 'callUniversityClick',
			cleso: cleSearchOrderPosition,
		});
	}
}
