import { Injectable, inject } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	abbreviateProduct,
	verifyUgso,
	verifyCleso,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class VisitWebsiteGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/**
	 * When user clicks visit website button
	 */
	visitWebsiteClick = (
		uniId: number,
		uniName: string,
		componentName: string,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) => {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'visit_website_button_click',
				university: uniId,
				universityName: uniName,
				componentName: componentName,
				product: abbreviateProduct(product),
				event: 'visitWebsiteClick',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	};
}
