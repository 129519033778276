import { inject, Injectable } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	determineTier,
	determineValue,
	isUserToNumber,
} from '../shared-functions/shared-functions';
import { InAppBrowserPlatform } from '../models/gtm.models';

@Injectable({ providedIn: 'root' })
export class ProspectusGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/*
  User clicks get prospectus button
*/
	getProspectusClick(componentName: string, ugSearchOrderPosition: number | null) {
		const trackingData = Object.assign(
			{
				eventName: 'get_prospectus_button_click',
				componentName: componentName,
				event: 'getProspectusClick',
			},
			ugSearchOrderPosition ? { ugso: ugSearchOrderPosition } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/*
  When the user starts the prospectus form, fires when first field is entered
*/
	prospectusStart(inAppBrowserPlatform: InAppBrowserPlatform) {
		this.dataLayerSrv.push({
			eventName: 'prospectus_form_start',
			event: 'prospectusStart',
			inAppBrowserPlatform,
		});
	}

	/*
  Fires per unique prospectus, to get individual university data
  from a given prospectus order
*/
	prospectusSuccess(
		totalOrdered: number,
		timeToComplete: number,
		inAppBrowserPlatform: InAppBrowserPlatform,
	) {
		this.dataLayerSrv.push({
			eventName: 'prospectus_form_success',
			orderTotal: totalOrdered,
			time: timeToComplete,
			event: 'prospectusSuccess',
			inAppBrowserPlatform,
		});
	}

	/*
  User clicks add all prospectus button in form
*/
	addAllProspectuses() {
		this.dataLayerSrv.push({
			eventName: 'add_prospectus_click',
			event: 'addProspectusClick',
		});
	}

	/*
  Fires per unique prospectus, to get individual university data from a given prospectus order"
*/
	prospectusOrdered(
		uniId: number,
		uniName: string,
		enquiry: 'checked' | 'notchecked',
		isUser: boolean,
		ugSearchOrderPosition: number | null,
		isBulkOrderEnabled: boolean,
		inAppBrowserPlatform: InAppBrowserPlatform,
	) {
		const tier = determineTier(uniId);
		const value = determineValue(uniId);

		const trackingData = Object.assign(
			{
				eventName: 'prospectus_ordered',
				tier: tier,
				university: uniId,
				universityName: uniName,
				value: value,
				enquiryTickBox: enquiry,
				user: isUserToNumber(isUser),
				bulk_order: isBulkOrderEnabled,
				event: 'prospectusOrdered',
				inAppBrowserPlatform,
			},
			ugSearchOrderPosition ? { ugso: ugSearchOrderPosition } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}
}
