import { inject, Injectable } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { DataLayerService } from '../data-layer/data-layer.service';
import { ProfileTab } from '../models';
import {
	abbreviateProduct,
	verifyCleso,
	verifyUgso,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class UniversityGTMService {
	private dataLayerSrv = inject(DataLayerService);

	uniImpression = (
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
		profileTab?: ProfileTab,
	) => {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'university_impression',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				profile_tab: profileTab,
				event: 'universityImpression',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	};

	uniListInViewPort = (totalUnis: number, product: Product | '' = '') => {
		this.dataLayerSrv.push({
			eventName: 'university_listing_view',
			totalUniversities: totalUnis,
			product: abbreviateProduct(product),
			event: 'universityView',
		});
	};

	saveUniClick = (
		componentName: string | null,
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) => {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'save_button_click',
				componentName: componentName,
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				event: 'saveClick',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	};

	uniProfileClick = (
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) => {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'university_profile_click',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				event: 'uniProfileClick',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	};

	/*
  User views the screen for a university profile
*/
	uniProfileView(
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'university_profile_view',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				event: 'universityProfileView',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}
}
