import { ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { isInAppBrowser } from '@uc/utilities';
import { of } from 'rxjs';
import { ScriptRemovalService } from '../../services/script-removal.service';
import { InAppBrowserService } from '../services/in-app-browser.service';

export const inAppBrowserGuard = (route: ActivatedRouteSnapshot) => {
	const scriptRemovalSrv = inject(ScriptRemovalService);
	const inAppBrowserSrv = inject(InAppBrowserService);

	const platform = route.queryParams['platform'];
	const ssoId = route.queryParams['id'];

	if (isInAppBrowser(platform)) {
		inAppBrowserSrv.resetState();
		inAppBrowserSrv.setIsInAppBrowser(true);
		inAppBrowserSrv.setPlatform(platform);
		inAppBrowserSrv.setSSOId(ssoId);
		scriptRemovalSrv.removeScripts();
	}

	return of(true);
};
