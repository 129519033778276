import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	determineTier,
	determineValue,
	isUserToNumber,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class ReviewGTMService {
	private dataLayerSrv = inject(DataLayerService);

	reviewStart(uniId: number, uniName: string) {
		this.dataLayerSrv.push({
			eventName: 'review_start',
			university: uniId,
			universityName: uniName,
			event: 'reviewStart',
		});
	}

	reviewSuccess(
		timeToComplete: number,
		isUser: boolean,
		uniId: number,
		uniName: string,
	) {
		this.dataLayerSrv.push({
			eventName: 'review_success',
			time: timeToComplete,
			tier: determineTier(uniId),
			value: determineValue(uniId),
			user: isUserToNumber(isUser),
			university: uniId,
			universityName: uniName,
			event: 'reviewSuccess',
		});
	}

	reviewButton(uniId: number, uniName: string) {
		this.dataLayerSrv.push({
			eventName: 'review_button_click',
			university: uniId,
			universityName: uniName,
			event: 'reviewButtonClick',
		});
	}
}
