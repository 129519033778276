import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Product, ProductEnum, UniversityUrlPath } from '@uc/web/shared/data-models';

@Injectable()
export class UniversityReadUrlService implements OnDestroy {
	private subdirectory!: UniversityUrlPath;
	private product!: Product;
	private subdirectory$ = new Subject<UniversityUrlPath>();
	private product$ = new Subject<Product>();
	private universityUrl$ = new Subject<string>();
	private url!: string;
	private instantiated = false;

	private routerSub!: Subscription;

	constructor(private router: Router) {
		this.url = this.router.url; // when the service is instantiated
		if (!this.instantiated) {
			this.triggerSetters();
			this.instantiated = true;
		}

		this.routerSub = this.router.events.subscribe((event: Event) => {
			// detects if the route changed
			if (event instanceof NavigationEnd) {
				this.url = this.router.url;
				this.triggerSetters();
			}
		});
	}

	getSubdirectory(): Observable<UniversityUrlPath> {
		return this.subdirectory$.asObservable();
	}

	// TODO: remove this method
	getProduct$(): Observable<Product> {
		return this.product$.asObservable();
	}

	getUniversityUrl() {
		return this.universityUrl$.asObservable();
	}

	getProduct() {
		return this.product;
	}

	setProductManually(product: Product) {
		this.product = product;
		this.product$.next(product);
	}

	private triggerSetters() {
		this.setSubdirectory();
		this.setUniversityUrl();
		this.setProduct();
	}

	private setSubdirectory() {
		this.subdirectory$.next(UniversityUrlPath.undergraduate);
		this.subdirectory = UniversityUrlPath.undergraduate;
		if (this.url.includes('postgraduate')) {
			this.subdirectory$.next(UniversityUrlPath.postgraduate);
			this.subdirectory = UniversityUrlPath.postgraduate;
		} else if (this.url.includes('clearing')) {
			this.subdirectory$.next(UniversityUrlPath.clearing);
			this.subdirectory = UniversityUrlPath.clearing;
		} else if (this.url.includes('open-days')) {
			this.subdirectory$.next(UniversityUrlPath.openDays);
			this.subdirectory = UniversityUrlPath.openDays;
		} else if (this.url.includes('reviews')) {
			this.subdirectory$.next(UniversityUrlPath.reviews);
			this.subdirectory = UniversityUrlPath.reviews;
		}
	}

	private setProduct() {
		if (this.subdirectory === 'reviews' || this.subdirectory === 'open-days') {
			return;
		}

		const strEnum = (this.subdirectory.charAt(0).toUpperCase() +
			this.subdirectory.slice(1)) as keyof typeof ProductEnum;
		this.product = ProductEnum[strEnum];
		this.product$.next(ProductEnum[strEnum]);
	}

	private setUniversityUrl() {
		if (this.router.url.includes('universities')) {
			const url = this.router.url.split('/').filter((elem) => elem);
			this.universityUrl$.next(url[1]);
		}
	}

	ngOnDestroy(): void {
		this.routerSub.unsubscribe();
	}
}
